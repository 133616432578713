<template>
	<div>
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Financiamiento y Cofinanciamiento</h1>
			</div>
		</div>

		<div class="d-flex flex-wrap">
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="card-title">Solicitudes</div>
						<div class="card-subtitle text-muted">Administración de solicites</div>
						<div class="card-text text-right">
							<router-link :to="{name: 'fyc_solicitudes'}" class="btn btn-outline-secondary">Acceder</router-link>
						</div>
					</div>
				</div>
			</div>									
		</div>

		<template v-if="$auth.can('fyc','usuario_operador')">
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Administración</h1>
			</div>
		</div>

		<div class="d-flex flex-wrap">
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="card-title">Solicitudes</div>
						<div class="card-subtitle text-muted">Administrador de Solicitudes</div>
						<div class="card-text text-right">
							<router-link :to="{name: 'fyc_admin_solicitudes'}" class="btn btn-outline-secondary">Acceder</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="card-title">Alianzas</div>
						<div class="card-subtitle text-muted">Administrador de alianzas</div>
						<div class="card-text text-right">
							<router-link :to="{name: 'fyc_admin_alianzas'}" class="btn btn-outline-secondary">Acceder</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="card-title">Configuración</div>
						<div class="card-subtitle text-muted">Administrador de configuración</div>
						<div class="card-text text-right">
							<router-link :to="{name: 'fyc_admin_configuracion'}" class="btn btn-outline-secondary">Acceder</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		</template>

	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			let menu = [
				{
					title: 'F y C',
					fyc_solicitudes: 'Solicitudes'
				}
			];

				if (this.$auth.can('fyc','usuario_operador')) {
				let admin = {
					title: 'Administración',
					fyc_admin_solicitudes: 'Solicitudes',
					fyc_admin_alianzas: 'Alianzas',
					fyc_admin_configuracion: 'Configuración'
				};
				menu.push(admin);
			}

			this.$store.commit('setMenu',menu);
		}
	}
</script>